import React from "react";

function Footer(){
    return(
        <div class="text-center p-6 bg-black footer">
     /*    <span class="text-white">Inspiration:  </span> */
        <a class="link-underline text-white font-bold" href="https://rahulkarda.netlify.app">Rahul Karda</a>
      </div>
    )
}


export default Footer