import React from "react";

const About = () => {
  return (
    <div
      name="about"
      className="w-full h-100 bg-gradient-to-b from-gray-800 to-black text-white about"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            About Me
          </p>
        </div>

        <p className="text-xl mt-5" align="justify">
          I’m Charan a software developer and I love the idea of bringing thoughts to life! Though I consider myself as a Software Generalist I have specific interest in Full Stack Development, System Development, Database Development, Functional Programming. I like to bring different perspectives into problems which helps me bring the best out of everything.

        </p>

        <br />

        <p className="text-xl" align="justify">
        I’m a forever learner; I have honed Analytical and Problem-solving skills with practice. Years of experience have developed my leadership skills and team-working capabilities. I'm an excellent team member who understands the value of collaboration. My experience to deal with a wide range of situations and individuals enabled me to deliver on the most difficult challenges. I've had a Nonlinear career path, wearing numerous hats while working with Public and Private Sectors, and Volunteering.  Going through a couple of career shifts and getting diverse experiences made me grow hungrier for challenging opportunities. I have a deep understanding of JavaScript internals with domain knowledge in Banking, e-commerce, FinTech. 
        </p>




 <br /> 
         <p className="text-xl" align="justify">
       I believe in consistency and clear communication. I am looking forward to aligning myself with a company and team where I can bring in positive change!  I’m conscious of my carbon footprint and motivated to work in Sustainable projects in the in the fullness of time.
 <br />
        </p>

      </div>
    </div>
  );
};

export default About;
